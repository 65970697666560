<template>
  <div class="not-found">
    <template v-if="image">
      <object
        v-if="isSVG"
        type="image/svg+xml"
        :data="image"
        alt="Error Logo"
        class="not-found-image"
      ></object>
      <img v-else :src="image" alt="Error Logo" class="not-found-image" />
    </template>
    <div class="not-found-message">{{ $t('notFound.message') }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      image: 'core/loadingImage',
    }),
    isSVG() {
      if (this.image && typeof this.image === 'string') {
        const suffix = '.svg';
        return (
          this.image.slice(-suffix.length).toLowerCase() ===
          suffix.toLowerCase()
        );
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/_not-found.scss';
</style>

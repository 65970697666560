import { sharedApi, locationApi } from '@/api/http/api';

const state = {
  sharedInfo: {},
  expiryDate: '',
  loading: true,
  apiDown: false,
};

const getters = {};

const actions = {
  async getSharedInfo(context: any, payload: any) {
    const postData: any = {};

    context.dispatch('core/setOrganizationLoadingImage', payload.loadingImage, {
      root: true,
    });

    try {
      const locationData = await locationApi.get();
      postData.latitude = locationData.latitude;
      postData.longitude = locationData.longitude;

      if (locationData.accuracy) {
        postData.locationAccuracy = locationData.accuracy;
      }
    } catch (err) {
      console.log('error getting location', err);
    }

    await sharedApi
      .submit(payload.orgId, payload.interactionId, postData)
      .then((response: any) => {
        context.commit('SET_INFO', response.data);
        context.commit('SET_LOADING');
      })
      .catch(() => {
        console.log('api does not work');
        context.commit('SET_API_DOWN');
        context.commit('SET_LOADING');
      });
  },
};

const mutations = {
  SET_INFO(state: any, payload: any) {
    state.sharedInfo = payload;
    state.expiryDate = payload.expiresAt;
  },
  SET_API_DOWN(state: any) {
    state.apiDown = true;
  },
  SET_LOADING(state: any) {
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import settings from '@/settings';
import Axios from 'axios';

export function getUrlParams(search: string) {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  const params: any = {};
  hashes.map((hash) => {
    const [key, val] = hash.split('=');
    params[key] = decodeURIComponent(val);
  });
  return params;
}

export function getCodePrefixes() {
  return ['rfuid:', 'gtin:', 'gs1_128:', 'bic:', 'asin:', 'ico:', 'rfmc:'];
}

export function getDefaultCodePrefix() {
  return getCodePrefixes()[0];
}

export async function checkBrokenList(initPath: string, orgId: string) {
  if (!settings.brokenLinks || !initPath) {
    return '';
  }
  try {
    const brokenLinksFile = settings.brokenLinks.replace(
      /{orgId}/g,
      String(orgId),
    );

    const brokenLinks: any = (await Axios.get(brokenLinksFile)).data;
    const wrongPaths = brokenLinks ? Object.keys(brokenLinks) : [];
    for (const wrongPath of wrongPaths) {
      if (initPath.includes(wrongPath)) {
        return brokenLinks[wrongPath];
      }
    }
  } catch (e) {
    return '';
  }
}

import { previewApi, locationApi } from '@/api/http/api';

const state = {
  publicInfo: {},
  loading: true,
  apiDown: false,
};

const getters = {};

const actions = {
  async getPublicInfo(context: any, payload: any) {
    const qs: any = {};

    try {
      const location = await locationApi.get();
      qs.latitude = location.latitude;
      qs.longitude = location.longitude;
    } catch (err) {
      console.log(err);
    }

    if (payload.context) {
      qs.context = payload.context;
    }

    await previewApi
      .getPreviewResult(payload.orgId, payload.thingId, payload.itemId, qs)
      .then((response: any) => {
        context.commit('SET_INFO', response.data);
        context.commit('SET_LOADING');
      })
      .catch(() => {
        context.commit('SET_API_DOWN');
        context.commit('SET_LOADING');
      });
  },
};

const mutations = {
  SET_INFO(state: any, payload: any) {
    state.publicInfo = payload;
  },
  SET_API_DOWN(state: any) {
    state.apiDown = true;
  },
  SET_LOADING(state: any) {
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import Vue from 'vue';
import { taskApi, locationApi } from '@/api/http/api';
import { getJwtClaims } from '@/api/utils/jwt';

interface IState {
  token: string | null;
  executionId: string | null;
  taskData: object | null;
}

const state: IState = {
  token: null,
  executionId: null,
  taskData: null,
};

const mutations = {
  SET_TOKEN: function (state: IState, payload: any) {
    localStorage.setItem('taskToken', payload);
    Vue.set(state, 'token', payload);
  },
  SET_ID: function (state: IState, payload: any) {
    Vue.set(state, 'executionId', payload);
  },
  SET_DATA: function (state: IState, payload: any) {
    Vue.set(state, 'taskData', payload);
  },
};

const actions = {
  exchangeToken: async function (context: any, token: string) {
    const postData: any = {};

    try {
      const locationData = await locationApi.get();

      postData.metadata = {
        metadata: {
          location: {
            ...(locationData.accuracy && {
              pointAccuracy: locationData.accuracy,
            }),
            point: {
              coordinates: [locationData.longitude, locationData.latitude],
            },
          },
        },
      };
    } catch (err) {
      console.log('error getting location', err);
    }

    return await taskApi
      .exchangeToken(token, postData)
      .then(async (response: any) => {
        const id = response.data.id;
        const token = response.data.token;
        return await getExecutionData(context, id, token);
      });
  },
  getFromCache: async function (context: any) {
    try {
      const token = localStorage.getItem('taskToken');
      const id = getJwtClaims(String(token)).executionId;

      if (!token || !id) {
        throw new Error('No Token in cache');
      }

      return await getExecutionData(context, id, token);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  process: function (context: any, updateData: any) {
    let pr = Promise.resolve(true as any);

    if (updateData) {
      pr = taskApi.updateExecution(
        context.state.executionId,
        context.state.token,
        updateData,
      );
    }

    return pr.then(() => {
      return taskApi
        .processExecution(context.state.executionId, context.state.token)
        .then((response: any) => {
          localStorage.removeItem('taskToken');
          return response.data;
        });
    });
  },
};

async function getExecutionData(context: any, id: string, token: string) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const publicExecution = await taskApi.getPublicExecution(id, token);

    context.commit('SET_TOKEN', token);
    context.commit('SET_DATA', {
      ...publicExecution.data,
    });
    context.commit('SET_ID', id);
    resolve(id);
  });
}

const getters = {};

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;

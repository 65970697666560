import i18n, { loadLocaleMessagesAsync } from '@/i18n';
import VueBodyClass from 'vue-body-class';
import VueRouter from 'vue-router';
import routes from './routes';

// const hasLoaded = false;
const { locale } = i18n;

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function () {
    return { x: 0, y: 0 };
  },
  routes, // short for routes: routes
  linkExactActiveClass: 'nav-item active',
});

const vueBodyClass = new (VueBodyClass as any)(routes);
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next);
});

router.beforeEach(async (to, from, next) => {
  await loadLocaleMessagesAsync(locale);

  // if not coming straight from wechat
  /*if (
    !hasLoaded &&
    !(
      from.name === null &&
      to.name === 'public' &&
      to.params &&
      to.params.channel === 'wechat'
    )
  ) {
    hasLoaded = true;

    Vue.use(
      VueGtag,
      {
        config: { id: settings.GtagID }, //google Analytics Gtag ID
        enabled: settings.GtagID && settings.GtagID.length,
      },
      router,
    );
  }*/

  next();
});

export default router;

export default {
  isLocal: process.env.VUE_APP_IS_LOCAL === '1',
  apiUrlGql: process.env.VUE_APP_apiUrlGql,
  apiUrlRest: process.env.VUE_APP_apiUrlRest,
  gcpPublicBucketUri: process.env.VUE_APP_gcpPublicBucketUri,
  recaptchaKey: process.env.VUE_APP_RECAPTCHA_KEY || '',
  googleGeoKey: process.env.VUE_APP_GOOGLE_GEO_KEY || '',
  GtagID: process.env.VUE_APP_GtagID || '',
  loadingImage: process.env.VUE_APP_loadingImage,
  brokenLinks: process.env.VUE_APP_brokenLinks,
};

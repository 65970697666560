import axios from 'axios';
import settings from '@/settings';
import { getBrowserLocale } from '@/utils/i18n';

const browserLocale = getBrowserLocale();

const api = axios.create({
  baseURL: settings.apiUrlRest,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': browserLocale,
  },
});

export const http = api;

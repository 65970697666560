import { qrApi } from '@/api/http/api';

const state = {
  resultInfo: {},
  expiryDate: '',
  loading: true,
  apiDown: false,
};

const getters = {};

const actions = {
  async getResultInfo(context: any, payload: any) {
    context.dispatch('core/setOrganizationLoadingImage', payload.loadingImage, {
      root: true,
    });

    await qrApi
      .getViewResult(payload.orgId, payload.interactionId)
      .then((response: any) => {
        context.commit('SET_INFO', response.data);
        context.commit('SET_LOADING');
      })
      .catch(() => {
        console.log('api does not work');
        context.commit('SET_API_DOWN');
        context.commit('SET_LOADING');
      });
  },
};

const mutations = {
  SET_INFO(state: any, payload: any) {
    state.resultInfo = payload;
    state.expiryDate = payload.expiresAt;
  },
  SET_API_DOWN(state: any) {
    state.apiDown = true;
  },
  SET_LOADING(state: any) {
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import {
  exchangeApi,
  codeApi,
  thingApi,
  locationApi,
  organizationApi,
} from '@/api/http/api';
import { getDefaultCodePrefix, checkBrokenList } from '@/utils';
import Vue from 'vue';

interface IState {
  code: string | null;
  publicInfo: any | null;
  taskResult: any | null;
  apiDown: boolean | false;
  token: string | null;
  recaptchaTest: boolean | false;
  initPath: string;
}

const state: IState = {
  code: null,
  publicInfo: null,
  taskResult: null,
  apiDown: false,
  token: null,
  recaptchaTest: false,
  initPath: '',
};

const mutations = {
  SET_CODE: function (state: IState, payload: any) {
    Vue.set(state, 'code', payload);
  },
  SET_TASK_RESULT: function (state: IState, payload: any) {
    Vue.set(state, 'taskResult', payload);
  },
  SET_PUBLIC_INFO: function (state: IState, payload: any) {
    Vue.set(state, 'publicInfo', payload);
  },
  SET_API_DOWN() {
    state.apiDown = true;
  },
  SET_API_ON() {
    state.apiDown = false;
  },
  SET_RECAPTCHA_TOKEN(state: IState, payload: any) {
    Vue.set(state, 'token', payload);
  },
  SET_RECAPTCHA_TEST(state: IState, payload: boolean) {
    Vue.set(state, 'recaptchaTest', payload);
  },
  SET_INIT_PATH(state: IState, payload: any) {
    Vue.set(state, 'initPath', payload);
  },
};

const actions = {
  setCode: async function (context: any, payload: any) {
    context.commit('SET_CODE', payload);
  },
  runTask: async function (context: any) {
    context.commit('SET_API_ON');
    context.commit('SET_TASK_RESULT', null);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      let code = context.state.code;

      try {
        if (!code) {
          throw new Error('No Code');
        }

        const codeParts = code.split('.');
        const isRfuid = code.indexOf(getDefaultCodePrefix()) === 0;

        if (isRfuid && codeParts.length != 3) {
          reject();
        }

        if (!isRfuid) {
          code =
            codeParts[0].split(':')[0] + ':' + codeParts.slice(1).join('.'); // remove orgId from code.
        }

        const jwt = await exchangeApi
          .getJwt({
            virtualCodeUuid: codeParts[0],
            recaptchaToken: context.state.token,
          })
          .then((response: any) => {
            return response.data.jwt;
          });

        let orgId = false;
        try {
          orgId = JSON.parse(atob(jwt.split('.')[1])).orgId || false;
          // Get Public Image from org's entity
        } catch (e) {
          orgId = false;
        }

        let publicPageImage = null;
        if (orgId) {
          try {
            const organization = await organizationApi.get(String(orgId), jwt);
            publicPageImage = organization
              ? organization.data.publicPageImage
              : publicPageImage;
          } catch (err) {
            console.log(err);
          }

          const correctPath = await checkBrokenList(
            context.state.initPath,
            String(orgId),
          );

          if (correctPath) {
            return resolve(correctPath);
          }
        }

        context.dispatch(
          'core/setOrganizationLoadingImage',
          { orgId: orgId, image: publicPageImage },
          {
            root: true,
          },
        );

        let locationData = null;
        try {
          locationData = await locationApi.get();
        } catch (err) {
          locationData = null;
        }

        const formData = new FormData();
        formData.append('image', code);
        formData.append('channel', 'Public page');

        if (locationData) {
          formData.append('latitude', locationData.latitude);
          formData.append('longitude', locationData.longitude);
          if (locationData.accuracy) {
            formData.append('locationAccuracy', locationData.accuracy);
          }
        } else {
          formData.append('locationError', '1');
        }

        const taskResult = await codeApi
          .submit(formData, {
            timeout: 1000 * 60,
            headers: {
              Authorization: `Bearer ${jwt}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response: any) => {
            return response.data;
          });

        const taskStatusResult =
          taskResult.status ||
          (await codeApi
            .status(taskResult.statusUrl, {
              headers: {
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then((response: any) => {
              return response.data;
            }));

        context.commit('SET_CODE', null);
        context.commit('SET_TASK_RESULT', taskStatusResult);
        resolve(taskStatusResult);
      } catch (err) {
        context.commit('SET_API_DOWN');
        reject(err);
      }
    });
  },
  setPublicInfo: async function (context: any, payload: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const organizationId = payload ? payload.organizationId : null;
      const interactionId = payload ? payload.interactionId : null;
      let publicInfo: any | null = null;

      try {
        const fromLink = organizationId === 'link';
        const qs: any = {};

        if (fromLink) {
          try {
            const location = await locationApi.get();
            qs.latitude = location.latitude;
            qs.longitude = location.longitude;
          } catch (err) {
            console.log(err);
          }
        }

        await thingApi
          .getPublic(organizationId, interactionId, qs)
          .then((response: any) => {
            publicInfo = response.data;
            if (publicInfo.interactions) {
              publicInfo.interactions.forEach((interaction: any) => {
                if (interaction.steps) {
                  interaction._stateOpened = false;
                }
              });
            }
            //add interactionId for status component
            if (interactionId) {
              publicInfo.interactionId = interactionId;
            }
            context.commit('SET_API_ON');
            context.commit('SET_PUBLIC_INFO', publicInfo);
          })
          .catch((error) => {
            context.commit('SET_API_DOWN');
            console.log(error);
          });
      } catch (err) {
        console.log(err);
        context.commit('SET_API_DOWN');
      }

      if (publicInfo) {
        resolve(publicInfo);
      } else {
        reject();
      }
    });
  },
};

const getters = {};

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;

import supportedLocales from '@/config/i18n/locales';

export function getBrowserLocale(languageCodeOnly = false) {
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  const trimmedLocale = languageCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();

  return trimmedLocale;
}

export function getSupportedLocales() {
  const annotatedLocales = [];

  for (const code of Object.keys(supportedLocales)) {
    annotatedLocales.push({
      code,
      name: (supportedLocales as any)[code],
    });
  }

  return annotatedLocales;
}

export function isLocaleSupported(locale: any) {
  return Object.keys(supportedLocales).includes(locale);
}

import settings from '@/settings';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import gql from 'graphql-tag';
import VueApollo from 'vue-apollo';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path, extensions }) => {
      let msg = message;
      try {
        msg = extensions ? extensions.responseBody.message : message;
      } catch (err) {
        msg = message;
      }
      // Condition created to avoid display errors between entities relationships
      if (path && path.length === 1) {
        // EventBus.$emit('notify::error', message);
      }
      console.log(
        `[GraphQL error]: Message: ${msg}, Location: ${locations}, Path: ${path}`,
      );
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

// HTTP connection to the API
const httpLink = createHttpLink({ uri: `${settings.apiUrlGql}` });

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  cache,
  //link
  link: ApolloLink.from([errorLink, httpLink]),
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    // apollo options applied to all queries in components
    $query: {
      loadingKey: 'loading',
      fetchPolicy: 'network-only',
    },
  },
});

export const apolloDefaultClient = apolloProvider.defaultClient;

export const GraphqlFastQuery = (query: string, options?: any) => {
  return apolloDefaultClient.query({
    ...options,
    query: gql(query),
  });
};

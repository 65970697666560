import Axios from 'axios';
import settings from '@/settings';
import Vue from 'vue';

interface IState {
  loadingImage: string | null;
  gdprEnabled: boolean;
  gdprPrompt: boolean;
  gdprAccepted: boolean;
}

const state: IState = {
  loadingImage: null,
  gdprEnabled: false,
  gdprPrompt: true,
  gdprAccepted: false,
};

const mutations = {
  SET_LOADING_IMAGE: function (state: IState, payload: any) {
    Vue.set(state, 'loadingImage', payload);
  },
  SET_GDPR: function (state: IState, payload: any) {
    Vue.set(state, 'gdprEnabled', payload);
  },
  SET_GDPR_PROMPT: function (state: IState, payload: any) {
    Vue.set(state, 'gdprPrompt', payload);
  },
  SET_GDPR_ACCEPTED: function (state: IState, payload: any) {
    Vue.set(state, 'gdprAccepted', payload);
  },
};

const actions = {
  enableGDPR: async function (context: any, payload: any) {
    context.commit('SET_GDPR', payload);
  },
  setOrganizationLoadingImage: async function (context: any, payload: any) {
    const orgId = payload ? payload.orgId || false : false;
    let loadingImage = payload ? payload.image : null;

    if (settings.loadingImage && !!orgId && !loadingImage) {
      loadingImage = await Axios.head(
        settings.loadingImage.replace(/{orgId}/g, String(orgId)) +
          '?rnd=' +
          new Date().getTime(),
      )
        .then((r) => String(r.config.url))
        .catch(() => null);
    }

    context.commit('SET_LOADING_IMAGE', loadingImage);
    return loadingImage;
  },
};

const getters = {
  loadingImage(state: any) {
    return state.loadingImage;
  },
  gdprEnabled(state: any) {
    return state.gdprEnabled;
  },
  gdprPrompt: (state: any) => {
    return state.gdprPrompt;
  },
  gdprAccepted: (state: any) => {
    return state.gdprAccepted;
  },
};

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;

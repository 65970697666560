<template>
  <div>
    <router-view></router-view>
    <gdpr-config v-if="gdprEnabled" v-slot:default="{ config }">
      <gdpr-cookies v-if="config" :data="config"></gdpr-cookies>
    </gdpr-config>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GdprConfig from '@/components/gdpr/gdprConfig.vue';
import GdprCookies from '@/components/gdpr/gdprCookies.vue';

export default {
  components: {
    GdprConfig,
    GdprCookies,
  },
  computed: {
    ...mapGetters({
      gdprEnabled: 'core/gdprEnabled',
    }),
  },
};
</script>

import Vue from 'vue';
import settings from '@/settings';
import store from '@/store';
import i18n from '@/i18n';
import momentTimezone from 'moment-timezone';
import { MomentInput } from 'moment-timezone';

const sizes: { [key: string]: string } = {
  S: '64',
  M: '128',
  L: '256',
  XL: '512',
  O: '',
};

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getItemStatus = (val: string) => {
  const publicInfo = (store.state as any).code.publicInfo;
  const itemStatuses =
    publicInfo && publicInfo.itemStatuses && publicInfo.itemStatuses.items
      ? publicInfo.itemStatuses.items
      : [];

  const words = val.split('_');
  const value = words[words.length - 1];
  const itemStatus = itemStatuses.find((i: any) => i.value == value);

  return itemStatus ? itemStatus.name : '';
};

(Vue as any).mixin({
  methods: {
    shortText(str: string, length = 0) {
      let ret = str.trim();
      if (length && ret.length > length) {
        ret = str.slice(0, length) + '...';
      }
      return ret;
    },
    mixinGetFilePath(path: string, size = 'M') {
      if (!path) return path;

      if (/^((http|https|ftp):\/\/)/.test(path)) {
        return path;
      }

      let newPath = path;
      const prefix = '@thumb_';
      const s: string = sizes[size];

      //case 1 - return path as it if coming from old interaction folder images or ask for origin images
      let pathArray = [];
      pathArray = newPath.split('/');
      if (
        (pathArray.length &&
          pathArray[0] === 'interactions' &&
          pathArray[1].indexOf('org_') === -1) ||
        s === ''
      ) {
        return `${settings.gcpPublicBucketUri}/${newPath}`;
      }

      if (s && !newPath.includes(prefix)) {
        newPath = newPath.replace(
          /\.(jpg|jpeg|gif|png)($|\?)/,
          `${prefix + s}.$1$2`,
        );
      }

      return `${settings.gcpPublicBucketUri}/${newPath}`;
    },
    getStatus(val: string) {
      if (val.includes('task_steps.status_check')) {
        return i18n.t('task_steps.status_check', {
          status: getItemStatus(val),
        });
      } else if (val.includes('item_status')) {
        return getItemStatus(val);
      }

      return false;
    },
    getTranslatedText(val: string) {
      const key =
        val && val.includes('|+|') ? val.split('|+|').shift() || '' : val;
      const value = val && val.includes('|+|') ? val.split('|+|').pop() : null;
      return key
        ? i18n.t(key.trim(), value ? { text: value.trim() } : {})
        : val;
    },
    mixinGetImageRegex() {
      return /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    },
    displayTimeZone: (data: MomentInput, dateFormat: string) => {
      return momentTimezone(data).tz(browserTimezone).format(dateFormat);
    },
    extractLocationData(details: any) {
      let locationData = {};
      const components = details.components || null;
      const locationDetails = !components
        ? []
        : components
            .filter((element: any) => {
              return element.types.includes('political');
            })
            .slice(-2);

      if (locationDetails.length > 0) {
        const city = locationDetails[0] ? locationDetails[0].long_name : null;
        const country = locationDetails[1]
          ? locationDetails[1].long_name
          : null;
        if (city) {
          locationData = city;
          if (country) {
            locationData = `${city}, ${country}`;
          }
        }
      } else {
        locationData = 'Unknown Location';
      }

      return locationData;
    },
    strippedHtml(str: string) {
      return str.replace(/<\/?[^>]+>/gi, '');
    },
  },
});

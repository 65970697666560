import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { isLocaleSupported, getBrowserLocale } from './utils/i18n';
Vue.use(VueI18n);

function getStartingLocale() {
  const browserLocale = getBrowserLocale(true);

  if (isLocaleSupported(browserLocale)) {
    return browserLocale;
  } else {
    return 'en';
  }
}

const startingLocale = getStartingLocale();

const i18n = new VueI18n({
  locale: startingLocale,
  fallbackLocale: 'en',
  messages: {},
});

const loadedLanguages: any = [];

export function loadLocaleMessagesAsync(locale: any) {
  if (loadedLanguages.length > 0 && i18n.locale === locale) {
    return Promise.resolve(locale);
  }

  // If the language was already loaded
  if (loadedLanguages.includes(locale)) {
    i18n.locale = locale;
    return Promise.resolve(locale);
  }
  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "locale-[request]" */ `@/config/i18n/locales/${locale}.json`
  ).then((messages) => {
    i18n.setLocaleMessage(locale, messages.default);
    loadedLanguages.push(locale);
    i18n.locale = locale;
    return Promise.resolve(locale);
  });
}

export default i18n;

<template>
  <md-snackbar md-position="center" :md-active.sync="show" md-persistent>
    <md-icon>lock</md-icon>
    <div class="rfider-cookies-link">
      {{ $t('cookies.cookies_content_1') + ' ' }}
      <a :href="data.cookieLink" target="_blank">
        {{ $t('cookies.cookie_policy') + ' ' }}
      </a>
      and
      <a :href="data.termsLink" target="_blank"
        >{{ $t('shared.terms_of_use') }}
      </a>
    </div>
    <div>
      <md-checkbox v-model="cookiePolicy" class="md-primary">{{
        $t('cookies.cookie_policy')
      }}</md-checkbox>
      <md-checkbox v-model="termsPolicy" class="md-primary">{{
        $t('shared.terms_of_use')
      }}</md-checkbox>
    </div>
    <md-button
      :disabled="!cookiePolicy || !termsPolicy"
      @click="isCookiesAccept(true)"
      class="rfider-cookies-btn-accept"
      >Accept</md-button
    >
    <md-button
      @click="isCookiesAccept(false)"
      class="rfider-cookies-btn-decline"
      >Decline</md-button
    >
    <div class="rfider-cookies-link-privacy">
      {{ $t('cookies.cookies_content_2') + ' ' }}
      <a :href="data.policyLink" target="_blank"
        >{{ $t('shared.privacy_policy', { '0': 'TradeWindow' }) }}
      </a>
      <span v-if="data.urlPrivacyPolicy">
        &nbsp;and&nbsp;
        <a :href="data.urlPrivacyPolicy" target="_blank">
          {{
            $t('shared.privacy_policy',
            { '0': data.entityName }),
          }}
        </a>
      </span>
    </div>
  </md-snackbar>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      cookiePolicy: false,
      termsPolicy: false,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    if (
      this.$cookies.get('rfider_gdpr') !== null &&
      this.$cookies.get('rfider_gdpr')['isAccept'] !== undefined &&
      this.$cookies.get('rfider_gdpr')['version'] !== undefined &&
      this.$cookies.get('rfider_gdpr')['version'] === this.data.version
    ) {
      store.commit('core/SET_GDPR_PROMPT', false);
      if (this.$cookies.get('rfider_gdpr')['isAccept'] === true) {
        store.commit('core/SET_GDPR_ACCEPTED', true);
      }
    }
  },
  computed: {
    ...mapGetters({
      gdprPrompt: 'core/gdprPrompt',
    }),
    show: {
      get() {
        return this.gdprPrompt;
      },
      set(value) {
        return value;
      },
    },
  },
  methods: {
    isCookiesAccept(value) {
      let rfiderGdpr = { isAccept: value, version: this.data.version };
      this.$cookies.set('rfider_gdpr', rfiderGdpr, '1y');
      store.commit('core/SET_GDPR_PROMPT', false);
      store.commit('core/SET_GDPR_ACCEPTED', value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/shared/_rfider-cookies.scss';
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import ModuleCore from '@/store/modules/core';
import ModuleCode from '@/store/modules/code';
import ModuleTask from '@/store/modules/task';
import ModuleShared from '@/store/modules/shared';
import ModuleQR from '@/store/modules/qr';
import ModulePreview from '@/store/modules/preview';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    core: ModuleCore,
    code: ModuleCode,
    task: ModuleTask,
    shared: ModuleShared,
    qr: ModuleQR,
    preview: ModulePreview,
  },
});

import NotFound from '@/pages/notFound.vue';
import store from '@/store';
import { getCodePrefixes, getDefaultCodePrefix } from '@/utils';

const Code = () =>
  import(/* webpackChunkName: "code" */ '@/pages/code/indexCode.vue');
const Public = () =>
  import(/* webpackChunkName: "public" */ '@/pages/public/indexPublic.vue');
const Task = () =>
  import(/* webpackChunkName: "task" */ '@/pages/task/indexTask.vue');
const Shared = () =>
  import(/* webpackChunkName: "shared" */ '@/pages/shared/indexShared.vue');
const QR = () => import(/* webpackChunkName: "qr" */ '@/pages/qr/indexQr.vue');
const previewPublic = () =>
  import(
    /* webpackChunkName: "previewPublic" */ '@/pages/preview/indexPreview.vue'
  );

const routes = [
  {
    path: '/check',
    component: Code,
    name: 'code',
  },
  {
    path: '/public/:orgId/:interactionId/:channel?',
    component: Public,
    name: 'public',
    meta: { bodyClass: 'public-page' },
  },
  {
    path: '/task',
    component: Task,
    name: 'task',
  },
  {
    path: '/shared/:orgId/:interactionId',
    component: Shared,
    name: 'shared',
  },
  {
    path: '/confirm/:orgId/:interactionId',
    component: QR,
    name: 'confirm',
  },
  {
    path: '/public/preview/item/:orgId/:thingId/:itemId?',
    component: previewPublic,
    name: 'previewPublic',
  },
  {
    path: '*',
    component: NotFound,
    beforeEnter: (to: any, from: any, next: any) => {
      store.commit('code/SET_INIT_PATH', to.path);

      let callNext = true;
      let code = to.query.c || to.path.slice(1);
      // We decided to handle links from qr codes this way to make the urls as short as possible
      if (callNext && code.split('.').length >= 2) {
        const params = to.query;
        if (
          process.env.VUE_APP_CaptchaTestEnabled &&
          params &&
          params.recaptcha
        ) {
          store.commit('code/SET_RECAPTCHA_TEST', !!Number(params.recaptcha));
        }

        const defaultPrefix = getDefaultCodePrefix();
        const validPrefix = getCodePrefixes();
        const groups = (
          new RegExp(`^(${validPrefix.join('|')})?(.*)`).exec(code) as any
        ).slice(1, 3);

        if (!groups[0]) {
          code = defaultPrefix + code;
        }

        // Replace if code is coming with '-' as separator instead of '.'
        const codeParts = code.split('.');
        if (code.indexOf(defaultPrefix) === 0 && codeParts.length === 2) {
          const dataParts = codeParts[1].split('-');
          if (dataParts && dataParts.length > 2) {
            const checkSum = dataParts[dataParts.length - 1];
            const codeSequence = dataParts[dataParts.length - 2];
            const prefix = dataParts[dataParts.length - 3];

            // Extract batchId from the URL using code parts + number of dashes (3)
            const batchId = codeParts[1].slice(
              0,
              -(checkSum.length + codeSequence.length + prefix.length + 3),
            );
            code = `${codeParts[0]}.${batchId}.${prefix}-${codeSequence}-${checkSum}`;
          }
        }

        store.dispatch('code/setCode', code);
        callNext = false;
        next({
          name: 'code',
          replace: true,
          ...(to.query.back && {
            query: {
              back: to.query.back,
            },
          }),
        });
      }

      if (callNext && code.length > 20) {
        try {
          const json = JSON.parse(atob(code));
          if (json.t === 's' && json.o && json.i) {
            callNext = false;
            next({
              name: 'shared',
              replace: true,
              params: {
                orgId: json.o,
                interactionId: json.i,
              },
              ...(json.n && {
                query: {
                  name: json.n,
                },
              }),
            });
          }
        } catch (err) {
          console.log('cannot parse url');
        }
      }

      if (callNext) {
        next();
      }
    },
  },
];

export default routes;
